.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    gap: 16px;
}
/* .workspace-breadcrumb {
    display: flex;
    overflow: hidden;
    white-space: nowrap; /* Prevent wrapping */
/* } */

.workspace-actions1 {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
    overflow: hidden;
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Show ... for overflow */
}

.action-label1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* Apply ellipsis */
    max-width: 280px; /* Adjust width as per requirement */
    display: inline-block;
    vertical-align: middle;
}

.workspace-icons {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.workspace-content {
    margin-bottom: -16px;
    height: calc(100% - 100px);
}
.workspace-actions1 {
    display: flex;
    align-items: center;
    gap: 8px;
}
.workspace-action {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}
.action-icon {
    height: 20px;
    width: 20px;
}
.action-label1 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: #404040;
}
.right-actions {
    display: flex;
    align-items: center;
    gap: 14px;
}
.empty-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
}
.empty-container-heading {
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}
.empty-container-subheading {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
.workspace-actions {
    display: flex;
    align-items: center;
    max-width: 200px; /* Adjust as needed */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.action-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust as needed */
    display: inline-block;
}
