.fixed-pagination-container {
    display: flex;
    flex-direction: column;
    height: 100%; /* Full height for the container */
}

.table-content {
    flex: 1; /* Grow to take available space */
    overflow: auto; /* Scroll if content exceeds */
}

.table-pagination {
    flex-shrink: 0; /* Prevent shrinking */
    margin-top: auto; /* Push pagination to the bottom */
}

.textEllipsis1 {
    white-space: nowrap;
    overflow: hidden;
    width: 500px;
    text-overflow: ellipsis;
    max-width: 350px; /* Matches the column width */
    display: inline-block;
    vertical-align: middle;
}
/* 
.textEllipsis:hover {
    overflow: visible;
    white-space: normal;
    position: absolute;
    background: #fff;
    z-index: 10;
    border: 1px solid #ccc;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */
thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root,
thead.MuiTableHead-root.css-1wbz3t9 {
    background-color: #eaeef5;
}
