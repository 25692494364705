.box-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    /* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.0784313725490196),
        0 1px 4px 0 rgba(0, 0, 0, 0.0392156862745098); */
    flex: 1;
}

.box-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.box-top span {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #21242c;
}

.box-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.box-bottom span {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #21242c;
}

@media (max-height: 760px) and (max-width: 1320px) {
    .box-top > img {
        height: 20px;
        width: 20px;
    }

    .box-top span {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #21242c;
    }

    .box-bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
}
