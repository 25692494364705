.dashboard-container {
    display: flex;
    height: 100vh;
}

.dashboard-content {
    height: 100vh;
    width: calc(100% - 6rem);
    background: #fafbfc;
}

@media (max-width: 1280px) or (max-height: 700px) {
    .dashboard-content {
        width: calc(100% - 5rem);
    }
}
