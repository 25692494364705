.dd-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    min-width: 140px;
}
.home-time-filter .dd-content {
    min-width: 155px;
    z-index: 10;
    top: 2rem;
    left: -12px;
}
.dd-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 173px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    top: 2.2rem;
    left: -17px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
}

.dd-content > div {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    flex-direction: column;
}

.dd-content > div:hover {
    background: #eaeef5;
}
