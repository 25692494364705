.search-container {
    padding: 0.8rem;
    /* border: 1px solid #e0e0e0; */
    /* box-shadow: 1px 2px 2px 0px #21242c1f inset; */
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-left: 3px;
}

.search-input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border-radius: 5px;
    outline: none;
}

.search-dropdown {
    width: 91%;
    margin-left: 2%;
    position: absolute;
    top: 92%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 1px 2px 2px 0px #21242c1f inset;
    border-radius: 5px;
    z-index: 30;
}

.search-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.search-item:hover {
    background-color: #f0f0f0;
}

.search-all-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 500;
}

.search-all-item:hover {
    background-color: #f0f0f0;
}
.search-all-item span {
    color: #0b5cd7;
}

.search-item-data {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: left;
}

.search-item-name {
    font-size: 1rem;
    font-weight: 500;
    color: #4b5362;
    max-height: 24px;
}

.search-item-path {
    font-size: 0.8rem;
    color: #4b5362;
    max-height: 24px;
}
.search-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between input and X icon */
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    background: white;
}

.search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 4px;
}

.search-icon {
    margin-left: 8px;
}

.clear-icon {
    cursor: pointer;
}
