.dashboard-navbar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #21242c;
}

.dashboard-navbar {
    height: 100vh;
    width: 6rem;
    background: #21242c;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    overflow-x: hidden;
    scrollbar-color: #ffffff #21242c;
}

.nav-item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav-item span {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #ffffff;
    text-wrap: nowrap;
}

.nav-item img {
    height: 20px;
    width: 20px;
}
.nav-item-icon {
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
    position: relative;
}
.counter {
    position: absolute;
    right: 3px;
    background: rgb(255, 0, 0);
    border-radius: 50%;
    padding: 2px;
    color: #ffffff;
    height: 16px;
    width: 16px;
    font-weight: 700;
    font-size: 10px;
}
.nav-item div:hover {
    background: #4b5362;
}

.nav-item div.active {
    background: #4b5362;
}
.nav-modules {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.nav-divider {
    width: 48px;
    height: 5px;
    background: #4b5362;
    margin: 1.5rem 1.5rem 0px;
}
.dashboard-navbar-bottom {
    background: #21242c;
}

@media (max-height: 760px) or (max-width: 1280px) {
    .dashboard-navbar-container {
        width: 5rem;
    }
    .nav-item span {
        font-size: 9px;
    }
    .dashboard-navbar {
        padding: 1.25rem;
        width: 5rem;
    }
    .nav-item-icon {
        padding: 8px;
    }
    .nav-divider {
        margin: 1.5rem 1rem 0px;
    }
}
