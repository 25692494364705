.table-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.table-data {
    overflow-x: auto;
    border-radius: 0.25rem;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
}
.table-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    min-height: 58px;
}
.table-header {
    padding: 0rem 0.5rem;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
}
.action-card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    box-shadow: 0px 4px 8px 0px #0000001a;
}
.action-text {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.action-number {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
}
.table-container table {
    width: 100%;
    /* height: calc(100% - 2rem); */
    border-collapse: collapse;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}
.table-container th,
td {
    padding: 8px;
    text-align: left;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: -0.6%;
    color: inherit;
}
.table-container th {
    cursor: pointer;
    background-color: #eaeef5;
    font-weight: 600;
}
.column-header {
    display: flex;
    gap: 2rem;
    align-items: center;
}
.marker {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #0072ce;
}
.table-container tr {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.6%;
    color: #000000;
    cursor: pointer;
    border: 1px solid #ddd;
    position: relative;
    height: 40px;
}
.table-container tr.selected {
    background-color: rgb(202 215 226);
    font-family: Montserrat;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.6%;
    color: #4b5362;
    position: relative;
}
.table-container tr.unread {
    position: relative;

    color: #0072ce;
}

.table-container tr:hover {
    background-color: #edf2f6;
}
.bold {
    font-weight: 700;
}
.table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
}
.table-pagination div {
    cursor: pointer;
    font-family: Montserrat;
    padding: 0.5rem;
    align-items: baseline;
}
.table-pagination span {
    font-family: Montserrat;
    padding: 0.5rem;
}
.table-wrapper {
    width: 100%;
}
.header-row {
    width: 100%;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    border: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: #edf2f6;
    z-index: 1;
}

.header-cell {
    width: 14%;
    padding: 1rem 0.5rem;
    display: flex;
    gap: 1rem;
}

.body-row {
    width: 100%;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #ddd;
}
.body-row.unread {
    position: relative;

    color: #0072ce;
}
.body-row.selected {
    background-color: rgb(202 215 226);
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.6%;
    color: #4b5362;
    position: relative;
}

.body-row:hover {
    background-color: #edf2f6;
}
.body-cell {
    width: 14%;
    padding: 1rem 0.5rem;
    vertical-align: middle;
    position: relative;
}
.header-cell:nth-child(1),
.body-cell:nth-child(1) {
    width: 24%;
}
.header-cell:nth-child(3),
.body-cell:nth-child(3) {
    width: 17%;
}
.header-cell:nth-child(4),
.body-cell:nth-child(4) {
    width: 17%;
}

@media (max-width: 1280px) {
    .header-row {
        font-size: 11px;
    }
    .body-row {
        font-size: 11px;
    }
}
