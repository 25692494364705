.home-page-container {
    width: 100%;
    height: 100%;
    padding: 2rem;
}

.home-time-filter {
    height: 2.5rem;
    border: 1px solid #0b5cd7;
    max-width: 155px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 5px;
}
.home-feeds {
    height: calc(100% - 2.75rem);
    display: flex;
    gap: 0.75rem;
}

.home-left-column {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-right-column {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-left-column-top {
    height: 10%;
    display: flex;
    gap: 1rem;
}

.home-left-column-middle {
    height: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-left-column-bottom {
    height: 30%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}
.home-alert-section {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    width: 100%;
}

.home-alert-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-alert-header span,
.home-info-feed-header span {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #212529 !important;
}

.home-alert-header div,
.home-info-feed-header div {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.46px;
    color: #0b5cd7;
    cursor: pointer;
}

@media (max-height: 760px) and (max-width: 1320px) {
    .home-alert-header span,
    .home-info-feed-header span {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 18px !important;
        line-height: 20px !important;
        color: #212529 !important;
    }
    .home-left-column-top {
        height: 12%;
    }

    .home-left-column-middle {
        height: 55%;
    }

    .home-left-column-bottom {
        height: 30%;
    }
}

.home-alerts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.alert-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 0.5rem;
    position: relative;
    cursor: pointer;
}
.alert-unread {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #0072ce;
}
.alert-left {
    width: 24px;
    display: flex;
    align-items: center;
}
.alert-left img {
    width: 34px;
    height: 34px;
}
.alert-center {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
    min-height: 3.5rem;
    justify-content: center;
}
.alert-right {
    width: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
}
.alert-date {
    position: absolute;
    right: 14px;
    bottom: 0;
    font-size: 8px;
    font-weight: 600;
}
.alert-title {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.alert-description {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.home-info-feed {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    height: 100%;
    width: 100%;
}

.home-info-feed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-info-feed-table {
    height: calc(100% - 5rem);
}

@media (min-width: 2731px) and (max-width: 4102px) {
    .invisibleScroller.Time_line {
        margin-left: -31%;
    }
}
@media (min-width: 2049px) and (max-width: 2725px) {
    .invisibleScroller.Time_line {
        margin-left: -27%;
    }
}
@media (min-width: 1820px) and (max-width: 2045px) {
    .invisibleScroller.Time_line {
        margin-left: -26%;
    }
}

@media (min-width: 1700px) and (max-width: 1800px) {
    .invisibleScroller.Time_line {
        margin-left: -24%;
    }
}
@media (min-width: 1500px) and (max-width: 1699px) {
    .invisibleScroller.Time_line {
        margin-left: -20%;
    }
}
@media (min-width: 1200px) and (max-width: 1499px) {
    .invisibleScroller.Time_line {
        margin-left: -15%;
    }
}
