.card-container {
    height: 100%;
    width: 100%;
    background: #fff;
    /* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.0784313725490196),
        0 1px 4px 0 rgba(0, 0, 0, 0.0392156862745098); */
    border: 1px solid #eaeef5;
    border-radius: 0.75rem;
    margin: 0 !important;
}
